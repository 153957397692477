<template>
  <div class="inner">
	<div id="demo"></div>
  </div>
</template>
<script>
  import Pdfh5 from "pdfh5";
  import "pdfh5/css/pdfh5.css";
  export default {
	data() {
	  return {
	    pdfh5: null
	  };
	},
	mounted() {
        if(document.getElementsByClassName('inner')[0]){
            document.getElementsByClassName('inner')[0].style.height = document.documentElement.clientHeight+'px';
        }
        //实例化
        this.pdfh5 = new Pdfh5("#demo", {
            pdfurl: process.env.NODE_ENV == 'production' ? "/pah/pdf/explain.pdf" : "/pdf/explain.pdf"
        });
        //监听完成事件
        this.pdfh5.on("complete", function (status, msg, time) {
            console.log("状态：" + status + "，信息：" + msg + "，耗时：" + time + "毫秒，总页数：" + this.totalNum)
        })
    }
  }
</script>

<style scoped>
	*{
	padding: 0;
	margin: 0;
	}
	html,body,.inner {
	width: 100%;
	height: 100%;
	}
</style>